@import url("./assets/fonts/fonts.css");

html,
body {
  font-family: "Public Sans", sans-serif !important;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.v-center {
  align-items: center;
}
