.nav-link {
  padding: 0 1rem;
  color: #fff;
  font-weight: 100;
  cursor: pointer;
}

.btn-rsvp {
  border: 1px solid #fff;
  color: #fff !important;
  font-family: 'Public Sans', sans-serif;
  font-weight: 100 !important;
}

.btn-rsvp:hover {
  color: #000 !important;
}

.logo {
  width: auto;
  height: 2.5rem;
}
